
import { Options, Vue } from 'vue-class-component'
import {
  ICampaign,
  IEmployeeRelativeRelation,
  Campaign,
  EmployeeRelativeRelation,
  EmploymentForm,
  IEmploymentForm,
} from '@/types'
import Spinner from '@/components/Spinner.vue'
import ErrorMessages from '@/components/Messages.vue'
import FormInputPersonalIdentityNumber from '@/components/FormInputPersonalIdentityNumber.vue'
import FormInputPhoneNumber from '@/components/FormInputPhoneNumber.vue'
import FormInputDate from '@/components/FormInputDate.vue'
import FormInputPostalCode from '@/components/FormInputPostalCode.vue'
import { CampaignService, EmployeeService, IAddUpdateNewEmployeePayload } from '@/services'

interface IFormData {
  firstName: string
  lastName: string
  personalIdentityNumber: string

  address: string
  address2: string
  postalCode: string
  city: string

  phoneHome: string
  phoneMobile: string

  relativeName: string
  relativePhone: string
  relativeRelation: IEmployeeRelativeRelation | null

  bankClearing: string
  bankAccount: string

  info: string

  employmentDate: Date | null
  employmentForm: IEmploymentForm | null

  campaign: ICampaign | null
}

@Options({
  name: 'AddNewEmployeeView',
  components: {
    Spinner,
    ErrorMessagesComponent: ErrorMessages,
    FormInputPersonalIdentityNumber,
    FormInputPhoneNumber,
    FormInputDate,
    FormInputPostalCode,
  },
})
export default class AddNewEmployeeView extends Vue {
  formData: IFormData = {
    firstName: '',
    lastName: '',

    personalIdentityNumber: '',

    address: '',
    address2: '',
    postalCode: '',
    city: '',

    phoneHome: '',
    phoneMobile: '',

    relativeName: '',
    relativePhone: '',
    relativeRelation: null,

    bankClearing: '',
    bankAccount: '',

    info: '',

    employmentDate: null,
    employmentForm: null,

    campaign: null,
  }

  loadingCampaigns = false
  campaigns: Campaign[] = []

  loadingEmploymentForms = false
  employmentForms: EmploymentForm[] = []

  loadingRelationTypes = false
  relationTypes: EmployeeRelativeRelation[] = []

  savingNewEmployee = false
  savingNewEmployeeSuccessId = -1

  mounted(): void {
    this.fetchCampaigns().then((campaigns: Campaign[]) => {
      this.campaigns = campaigns
    })

    this.fetchEmploymentForms().then((employmentForms: IEmploymentForm[]) => {
      this.employmentForms = employmentForms
    })

    this.fetchRelationTypes().then((relationTypes: IEmployeeRelativeRelation[]) => {
      this.relationTypes = relationTypes
    })

    this.$nextTick(() => (this.$refs.formFirstName as HTMLInputElement).focus())
  }

  async fetchCampaigns(): Promise<Campaign[]> {
    try {
      this.loadingCampaigns = true
      return await CampaignService.fetchCampaigns()
    } catch (err: unknown) {
      ;(this.$refs.errorHandler as ErrorMessages).pushErr(err)
      return []
    } finally {
      this.loadingCampaigns = false
    }
  }

  async fetchEmploymentForms(): Promise<EmploymentForm[]> {
    try {
      this.loadingEmploymentForms = true
      return await EmployeeService.fetchEmploymentForms()
    } catch (err: unknown) {
      ;(this.$refs.errorHandler as ErrorMessages).pushErr(err)
      return []
    } finally {
      this.loadingEmploymentForms = false
    }
  }

  async fetchRelationTypes(): Promise<EmployeeRelativeRelation[]> {
    try {
      this.loadingRelationTypes = true
      return await EmployeeService.fetchEmployeeRelativeRelations()
    } catch (err: unknown) {
      ;(this.$refs.errorHandler as ErrorMessages).pushErr(err)
      return []
    } finally {
      this.loadingRelationTypes = false
    }
  }

  clearForm(): void {
    this.formData = {
      firstName: '',
      lastName: '',
      personalIdentityNumber: '',
      address: '',
      address2: '',
      postalCode: '',
      city: '',
      phoneHome: '',
      phoneMobile: '',
      relativeName: '',
      relativePhone: '',
      relativeRelation: null,
      bankClearing: '',
      bankAccount: '',
      info: '',
      employmentDate: null,
      employmentForm: null,
      campaign: null,
    }
  }

  async saveNewEmployee(): Promise<void> {
    try {
      this.savingNewEmployee = true
      ;(this.$refs.saveNewEmployeeErrorHandler as ErrorMessages).clear()

      const payload: IAddUpdateNewEmployeePayload = {
        firstName: this.formData.firstName,
        lastName: this.formData.lastName,

        personalIdentityNumber: this.formData.personalIdentityNumber.replace(/[^0-9]/g, ''),

        address: this.formData.address,
        address2: this.formData.address2,
        postalCode: this.formData.postalCode.replace(/[^0-9]/g, ''),
        city: this.formData.city,

        phoneHome: this.formData.phoneHome.replace(/[^0-9]/g, ''),
        phoneMobile: this.formData.phoneMobile.replace(/[^0-9]/g, ''),

        relativeName: this.formData.relativeName,
        relativePhone: this.formData.relativePhone.replace(/[^0-9]/g, ''),
        relativeRelationId: this.formData.relativeRelation?.id,

        bankClearing: this.formData.bankClearing.replace(/[^0-9]/g, ''),
        bankAccount: this.formData.bankAccount.replace(/[^0-9]/g, ''),

        info: this.formData.info,

        employmentDate: this.formData.employmentDate?.toISOString().substring(0, 10),
        employmentFormId: this.formData.employmentForm?.id,

        campaignId: this.formData.campaign?.id,
      }

      const newEmployee = await EmployeeService.createNewEmployee(payload)

      this.savingNewEmployeeSuccessId = newEmployee.id
      this.clearForm()
    } catch (err: unknown) {
      ;(this.$refs.saveNewEmployeeErrorHandler as ErrorMessages).pushErr(err)
    } finally {
      this.savingNewEmployee = false
    }
  }
}
